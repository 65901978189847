import React from 'react';

function Header() {
    return ( 

<header id="header" className="fixed-top ">
<div className="container d-flex align-items-center">
{/* <h1 className="logo mr-auto"><a href="#home">STS</a></h1> */}
  <a href="index.html" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"/></a>

  <nav className="nav-menu d-none d-lg-block">
    <ul>
      <li className="active"><a href="index.html">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#team">Team</a></li>
      <li><a href="#contact">Contact</a></li>
      <li><a href="http://starixc.co.ke/resume/index.html" target="_blank" rel="noopener noreferrer">Profile</a></li>

    </ul>
  </nav>
</div>
</header>

);
}

export default Header;