import React from 'react';

function Hero() {
    return ( 
<section id="hero" className="d-flex align-items-center">

<div className="container">
  <div className="row">
    <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
      <h1>Better Solutions For Your Business</h1>
      <h2>Starixc Tech Solution is an IT Based Firm committed to developing solutions that meet your needs, that are simple to understand and easy to use.</h2>
      <div className="d-lg-flex">
        <a href="#about" className="btn-get-started scrollto">Get Started</a>
      </div>
    </div>
    <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
      <img src="assets/img/hero-img.png" className="img-fluid animated" alt=""/>
    </div>
  </div>
</div>
<div class="wave-container">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#37517e" fill-opacity="1" d="M0,96L60,96C120,96,240,96,360,96C480,96,600,96,720,101.3C840,107,960,117,1080,117.3C1200,117,1320,107,1380,101.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
    </div>
</section>
 
);
}

export default Hero;