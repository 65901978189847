import React from 'react';
import './footer.css';

function Footer() {
    return ( 
<footer>
  {/* <!-- Footer main --> */}
  <section className="ft-main">
    <div className="ft-main-item">
      <h2 className="ft-title">About</h2>
      <ul>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="http://starixc.co.ke/resume/index.html" target="_blank" rel="noopener noreferrer">Profile</a></li>
        <li><a href="#customer">Customers</a></li>
        <li><a href="#career">Careers</a></li>
      </ul>
    </div>
    <div className="ft-main-item">
      <h2 className="ft-title">Resources</h2>
      <ul>
        <li><a href="#docs">Docs</a></li>
        <li><a href="#blog">Blog</a></li>
        <li><a href="#ebooks">eBooks</a></li>
        <li><a href="#webinar">Webinars</a></li>
      </ul>
    </div>
    <div className="ft-main-item">
      <h2 className="ft-title">Contact</h2>
      <ul>
        <li><a href="#help">Help</a></li>
        <li><a href="#sales">Sales</a></li>
        <li><a href="#adverts">Advertise</a></li>
      </ul>
    </div>
    <div className="ft-main-item">
      <h2 className="ft-title">Stay Updated</h2>
      <p>Subscribe to our newsletter to get our latest news.</p>
      <form>
        <input type="email" name="email" placeholder="Enter email address"/>
        <input type="submit" value="Subscribe"/>
      </form>
    </div>
  </section>

  {/* <!-- Footer social --> */}
  <section className="ft-social">
    <ul className="ft-social-list">
      <li><a href="#facebook"><i className="fab fa-facebook"></i></a></li>
      <li><a href="#twitter"><i className="fab fa-twitter"></i></a></li>
      <li><a href="#instagram"><i className="fab fa-instagram"></i></a></li>
      <li><a href="#github"><i className="fab fa-github"></i></a></li>
      <li><a href="#linkedin"><i className="fab fa-linkedin"></i></a></li>
      <li><a href="#youtube"><i className="fab fa-youtube"></i></a></li>
    </ul>
  </section>

  {/* <!-- Footer legal --> */}
  <section className="ft-legal">
    <ul className="ft-legal-list">
      <li><a href="#home">Terms &amp; Conditions</a></li>
      <li><a href="#privacy">Privacy Policy</a></li>
      <li>&copy; 2021 Copyright Starixc Tech Solution.</li>
    </ul>
  </section>
</footer>
);
}

export default Footer;