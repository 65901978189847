import React from 'react';

function Clients() {
    return ( 
        <main id="main">
    <section id="clients" className="clients section-bg">
      <div className="container">

        <div className="row" data-aos="zoom-in">

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/machakosschool.png" className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/nipk.jpg" className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/mgpk.jpg" className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/logo.svg" className="img-fluid" alt=""/>
          </div>

          {/* <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-5.png" className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="assets/img/clients/client-6.png" className="img-fluid" alt=""/>
          </div> */}

        </div>

      </div>
    </section>
{/* section about us */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="200">
          <img src="assets/img/about-us.png" className="img-fluid animated about-img" alt=""/>
            
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            <p>
            Our business support includes the supply, installation, upgrade and maintenance of PCs.
             It is our aim to forge a partnership so that our customers can develop and profit from Tech technology.
              Whatever your Tech requirements there is always one common factor - you need to be sure that your IT solution provider is able to provide the right solution at a competitive price coupled with excellent support and after-sales service.
            </p>
            </p>
            <a href="#about" className="btn-learn-more">Learn More</a>
          </div>
        </div>

      </div>
    </section>
    {/* Section Services */}
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Services</h2>
          <p>Our support includes the supply, installation, upgrade and maintenance of PCs.
               Whatever your Tech requirements there is always one common factor - you need to be sure that your
                IT solution provider is able to provide the right solution at a competitive price coupled with excellent support and after-sales service.</p>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4><a href="#services">WEB DESIGN and Hosting</a></h4>
              <p>As a web design firm we engage in creative architecture of developing and maintaining websites.
                   We have the best web graphic designers experienced in interface designing, authoring & coding using proprietary software that guarantees the best user experience</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4><a href="#services">ANDROID</a></h4>
              <p>
                  In a world where real processing has moved to hand held devices and smart phones,
                   we ensure that our clients are not left out. We pride ourselves in building modern,
                    flexible and user-friendly mobile apps that will allow clients do their business on the go.
                    We provide quick Solution for your android Development Needs at an a fordable price, contact us
             </p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4><a href="#services">Web Application</a></h4>
              <p>We create user friendly and interactive web applications, programs & other digital solutions.
                   We deliver these reliable services within the set timelines while ensuring quality & achieving optimal results. 
                  Our team is also knowledgeable in application domain, specialized algorithms & software engineering.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-layer"></i></div>
              <h4><a href="#services">SEO OPTIMIZE</a></h4>
              <p>Search Engine Optimization (SEO) make your website visible on a search engine result page for particular keywords or combinations of keywords.
                    The end-goal in any marketing venture is to generate a return on your investment.
                   Also  SEO is capable of generating very attractive returns by bringing people to your website through search engine.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    {/* Call to action section */}
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">

        <div className="row">
          <div className="col-lg-9 text-center text-lg-left">
            <h3>Quotation</h3>
            <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" href="#quote">Free Quote</a>
          </div>
        </div>

      </div>
    </section>
    <section className="project-area" id="portfolio">
            <div className="container">
                <div className="project-title pb-5 text-center">
                    <h1 className="text-uppercase title-h1">Recently Done Project</h1>
                    <h1 className="text-uppercase title-h1">Quality Work</h1>
                </div>


                <div className="row grid">
                    <div className="col-lg-4 col-md-6 col-sm-12 element-item latest">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://www.nipk.or.ke" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/nipk.png" alt="portfolio-1" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">NIPK</h4>
                                <span className="text-secondary">Network of Information Professional of kenya</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 element-item popular">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://duduaxe.starixc.co.ke" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets//img/portfolio/duduaxe.jpg" alt="portfolio-2" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">DuduAxe</h4>
                                <span className="text-secondary">DuduAxe Company Website</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 element-item popular">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://ecommerce.starixc.co.ke" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/nakurutechstore.png" alt="portfolio-3" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">Tech Store Africa</h4>
                                <span className="text-secondary">Ecommerce Full Stack</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 element-item upcoming">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://machakosschool.starixc.co.ke" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/msch.png" alt="portfolio-4" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">Machakos School</h4>
                                <span className="text-secondary">Under Development</span>
                            </div>
                        </div>
                    </div>
              <div className="col-lg-4 col-md-6 col-sm-12 element-item upcoming">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://surmoned.starixc.co.ke" target="_blank"rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/surmoned.png" alt="portfolio-5" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">SURMONED</h4>
                                <span className="text-secondary">Suba Resource Mobilization Network for Development</span>
                            </div>
                        </div>
                    </div>
                          <div className="col-lg-4 col-md-6 col-sm-12 element-item following">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://www.mazingiragreenparty.com/" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/mazingira.png" alt="portfolio-6" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">MGPK</h4>
                                <span className="text-secondary">Mazingira Green Party of Kenya</span>
                            </div>
                        </div>
                    </div>
                     <div className="col-lg-4 col-md-6 col-sm-12 element-item following">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="http://www.egerton.ac.ke/euajira/index.php" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/euajira.png" alt="portfolio-7" className="img-fluid"/>
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">EU-AJIRA</h4>
                                <span className="text-secondary">Egerton University Ajira Programme</span>
                            </div>
                        </div>
                    </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 element-item following">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://covid-19-tracker-1df3d.web.app/" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/covid.png" alt="portfolio-8" className="img-fluid" />
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">Covid-19 Tracker APP Beta Version</h4>
                                <span className="text-secondary">Asynch Application for Tracking changes in the number of Cases Accross the World</span>
                            </div>
                        </div>
                    </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 element-item following">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="https://rvsc.co.ke/" target="_blank" rel="noopener noreferrer">
                                    <img src="./assets/img/portfolio/rvsc.png" alt="Rift Valley Sports Club" className="img-fluid" />
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">Rif Valley Sports Club</h4>
                                <span className="text-secondary">RVSC Web Redesign</span>
                            </div>
                        </div>
                    </div>
                   {/* <!--   <div className="col-lg-4 col-md-6 col-sm-12 element-item upcoming">
                        <div className="our-project">
                            <div className="img">
                                <a className="test-popup-link" href="./img/portfolio/p9.jpg">
                                    <img src="./img/portfolio/p9.jpg" alt="portfolio-9" className="img-fluid">
                                </a>
                            </div>
                            <div className="title py-4">
                                <h4 className="text-uppercase">Camera Lens</h4>
                                <span className="text-secondary">Upcoming, Portfolio</span>
                            </div>
                        </div>
                    </div>--> */}
                </div>
            </div> 
        </section>
        <section id="testimonials" className="testimonials section-bg">
 <div className="container">

   <div className="section-title" data-aos="fade-up">
     <h2>Testimonials</h2>
     <p>Our web solutions are built to be customizable and scalable to accommodate business growth and expansion</p>
   </div>

   <div className="owl-carousel testimonials-carousel" data-aos="fade-up" data-aos-delay="200">

     <div className="testimonial-wrap">
       <div className="testimonial-item">
         <img src="assets/img/testimonials/Team1.jpg" className="testimonial-img" alt=""/>
         <h3>Mercy Kentrone</h3>
         <h4>Chairperson NIPK</h4>
         <p>
           <i className="bx bxs-quote-alt-left quote-icon-left"></i>
           The NIPK team would like to thank Starixc Tech Solutions for the quality of web maintenance services that they have provided and other support inclusive of graphical Designs for posters and Barners. 
           They have always provided professional, courteous and timely services. 
           For these reasons, we highly recommend StarixcTech Solutions Solution for any business that needs a website design or ongoing maintenance services.
           <i className="bx bxs-quote-alt-right quote-icon-right"></i>
         </p>
       </div>
     </div>

   {/*  <div className="testimonial-wrap">
       <div className="testimonial-item">
         <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt=""/>
         <h3>Sara Wilsson</h3>
         <h4>Designer</h4>
         <p>
           <i className="bx bxs-quote-alt-left quote-icon-left"></i>
           Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
           <i className="bx bxs-quote-alt-right quote-icon-right"></i>
         </p>
       </div>
     </div>

      <div className="testimonial-wrap">
       <div className="testimonial-item">
         <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt=""/>
         <h3>Jena Karlis</h3>
         <h4>Store Owner</h4>
         <p>
           <i className="bx bxs-quote-alt-left quote-icon-left"></i>
           Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
           <i className="bx bxs-quote-alt-right quote-icon-right"></i>
         </p>
       </div>
     </div>

     <div className="testimonial-wrap">
       <div className="testimonial-item">
         <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt=""/>
         <h3>Matt Brandon</h3>
         <h4>Freelancer</h4>
         <p>
           <i className="bx bxs-quote-alt-left quote-icon-left"></i>
           Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
           <i className="bx bxs-quote-alt-right quote-icon-right"></i>
         </p>
       </div>
     </div>

     <div className="testimonial-wrap">
       <div className="testimonial-item">
         <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt=""/>
         <h3>John Larson</h3>
         <h4>Entrepreneur</h4>
         <p>
           <i className="bx bxs-quote-alt-left quote-icon-left"></i>
           Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
           <i className="bx bxs-quote-alt-right quote-icon-right"></i>
         </p>
       </div>
     </div> */}

   </div>

 </div>
</section>
    </main>

);
}

export default Clients;